import React from "react";
import {
  FacebookShareButton,
  TelegramShareButton,
  EmailShareButton,
  TwitterShareButton,
} from "react-share";
import { ListItemText, Box, Button } from "@material-ui/core";
import List from "@material-ui/core/List";
import { FaFacebookF, FaInstagramSquare } from "react-icons/fa";

import { FaTwitter } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { MdEmail } from "react-icons/md";

const useStyles = makeStyles(() => ({
  shareModal: {
    paddingBottom: "38px",
    "& svg": {
      color: "rgba(255, 255, 255, 0.6)",
    },
    "& .MuiButton-root:hover": {
      backgroundColor: "transparent",
    },
  },
  hover: {
    border: "0.767442px solid rgba(255, 255, 255, 0.4)",
    borderRadius: "8px",
    padding: "12px 17px 5px",
    "&:hover": {
      backgroundColor: "transparent",
      border: "0.767442px solid #00FBFB",
      "& svg": {
        color: "#00FBFB",
      },
    },
  },
}));
export default function ShareSocialMedia({ url }) {
  const classes = useStyles();
  return (
    <List className={classes.shareModal}>
      <Button>
        <Box className={classes.hover}>
          {/* <FacebookShareButton url={url} target="_blank" > */}
          <FacebookShareButton
            url={url}
            target="_blank"
            href="https://www.facebook.com"
          >
            <FaFacebookF style={{ fontSize: "30px" }} /> <br />
          </FacebookShareButton>
        </Box>
      </Button>
      <Button>
        <Box className={classes.hover}>
          <EmailShareButton
            // url={url}
            // email="info@hovr.site"
            // subject="Check out this item on Hovr"
            className="Demo_some-network__share-button"
          >
            <MdEmail style={{ fontSize: "30px" }} /> <br />
          </EmailShareButton>
        </Box>
      </Button>
      <Button>
        <Box className={classes.hover}>
          <TelegramShareButton
            url={url}
            title={"Check out this item on Tarality"}
          >
            <FaTelegramPlane style={{ fontSize: "30px" }} /> <br />
          </TelegramShareButton>
        </Box>
      </Button>
      <Button>
        <Box className={classes.hover}>
          <TwitterShareButton
            url={url}
            title={`Check out this item on Tarality`}
            // hashtag="#camperstribe"
          >
            <FaTwitter style={{ fontSize: "30px" }} /> <br />
          </TwitterShareButton>{" "}
        </Box>
      </Button>
    </List>
  );
}
