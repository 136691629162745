import React, { useEffect, useContext, useState } from "react";
import {
  Typography,
  Box,
  makeStyles,
  FormHelperText,
  IconButton,
  Button,
  Container,
  Grid,
  FormGroup,
  FormControlLabel,
  Link as RouterLink,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { ACTIVE_NETWORK, getNetworkDetails } from "src/constants";
import { FaRegCopy } from "react-icons/fa";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import ButtonCircularProgress from "./ButtonCircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import { GiCancel } from "react-icons/gi";
import { UserContext } from "src/context/User";
import ErrorIcon from "@material-ui/icons/Error";
import { useWeb3React } from "@web3-react/core";

import { SUPPORTED_WALLETS } from "src/connectors";

const useStyles = makeStyles((theme) => ({
  walletBox: {
    // background: "#FFFFFF",
    border: "1px solid #E9E9E9",
    // boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.12)",
    borderRadius: "25px",
    // padding: "30px",
    textAlign: "center",
    cursor: "pointer",
    // marginBottom:"50px",
    border: "1px solid transparent",
    "& img": {
      maxWidth: "100%",
    },
    "& h5": {
      fontWeight: "600",
      fontSize: "22px",
      lineHeight: "33px",
      color: "#000000",
    },
    "& lavel": {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#3D3D3D",
    },
  },
  paper: {
    overflowY: "unset",
  },
  customizedButton: {
    position: "absolute",
    top: "-42px",
    right: "-9px",
    color: "#fff",
  },
  dialogBox: {
    padding: "30px",
    width: "540px !important",
    // "@media(max-width:500px)": {
    //   width: "300px !important",
    // },
    // "@media(max-width:400px)": {
    //   width: "270px !important",
    // },
  },
  metamaskcolor: {
    display: "flex",
    justifyContent: "center",
    // background: "rgb(255 255 255 / 43%)",
    // backdropFilter: "blur(100px)",
    // padding: "15px",
    // borderRadius: "20px",
    // maxWidth: "721px",
    padding: "10px",

    margin: "0 auto",
    "& .imagebox": {
      "& img": {
        width: "100%",
        borderRadius: "20px",
      },
    },
  },
  textItembox: {
    marginTop: " -24px",
    paddingBottom: "20px",
    "& h3": {
      textAlign: "center",
      color: "#fff",
      fontSize: "30px",
      fontWeight: "300",
    },
  },
  subadmin: {
    padding: "0px 0px 0px 17px",
    background: "rgba(0, 0, 0, 0.3)",
    backdropFilter: "blur(15px)",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h5": {
      color: "#E78B3A",
      fontWeight: "300",
    },
  },
  subadminmetamask: {
    display: "flex",
    alignItems: "center",
    width: "50px",
    height: "50px",
    justifyContent: "center",
    background: "rgba(255, 255, 255, 0.4)",
    padding: "4px",
    borderTopRightRadius: "9px",
    borderBottomRightRadius: "9px",
  },
  setting: {
    background: "rgba(0, 0, 0, 0.3)",
    width: "60px",
    padding: "10px 10px 1px",
    borderRadius: "10px",
    "& img": {
      width: "60px",
      height: " 60px",
    },
  },
}));

export default function UsersCard(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checked, setChecked] = React.useState(false);
  const [checkedTerms, setCheckedTerms] = React.useState(false);
  const [networkDetails, setNetworkDetails] = React.useState([]);
  const history = useHistory();
  const user = useContext(UserContext);

  const { activate, account, chainId, deactivate, library } = useWeb3React();

  // useEffect(() => {
  //   if (account === undefined) {
  //     setIsloader(true);
  //   } else {
  //     setIsloader(false);
  //   }
  // }, [account]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    try {
      const NetworkDetails = getNetworkDetails(ACTIVE_NETWORK);

      setNetworkDetails(NetworkDetails[0]);
    } catch (error) {
      console.log("ERROR", error);
    }
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const {
    type,
    data,
    onWalletConnectHandler,
    isLoading,
    selectedWallet,
    errorMsg,
    isLoaderwallet,
    handleClickOpen2,
    open2,
    setOpen2,
    open3,
    setOpen3,
    handleClickOpen3,
  } = props;
  const classes = useStyles();

  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleChangecheckedTerms = (event) => {
    setCheckedTerms(event.target.checked);
  };
  // useEffect(() => {
  //   if (errorMsg !== "" && handleClickOpen3) {
  //     handleClickOpen3();
  //   }
  // }, [errorMsg]);
  const walletBlocktoast = () => {
    toast.warn("You have been blocked");
    handleClose2();
    history.push("/");
  };

  return (
    <Box>
      <Box className={classes.metamaskcolor}>
        <Grid container spacing={4} alignItems="center">
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Box className="imagebox">
              <img src="images/modalItem.png" alt="" width="100%" />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Box className={classes.textItembox}>
              <Typography variant="h3">Login with wallet.</Typography>
            </Box>
            <Box
              className={classes.walletBox}
              onClick={handleClickOpen2}
              //  onClick={() => setTermCondition(true)}
            >
              {SUPPORTED_WALLETS.map((item, i) => {
                return (
                  <Box
                    key={i}
                    className={classes.metamaskhead}
                    setOpen2={setOpen2}
                    open2={open2}
                    handleClickOpen2={handleClickOpen2}
                    onWalletConnectHandler={(data) => {
                      onWalletConnectHandler(data);
                      // setSelectedWallet(data.name);
                    }}
                    index={i}
                    data={SUPPORTED_WALLETS[0]}
                  >
                    <Box className={classes.subadmin}>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h5"> {item.data?.name}</Typography>
                      </Box>

                      <Box className={classes.subadminmetamask}>
                        <img
                          src={item.data?.iconName}
                          alt=""
                          width="100%"
                          style={{ width: "30px" }}
                        />
                      </Box>
                    </Box>
                  </Box>
                );
              })}
              {isLoaderwallet && (
                <Box pt={1}>
                  <Typography>Please wait connecting ...</Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>

        {/* QI wallet */}
        <Box>
          <Dialog
            // open={user.walletError}
            // onClose={user.setwalletError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Box>
                  <Typography
                    style={{
                      fontSize: "20px",
                      color: "rgb(34, 167, 240)",
                      fontWeight: " 600",
                    }}
                  >
                    Please connect the website with QI Wallet
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    style={{
                      fontSize: " 16px",
                      fontWeight: " 500",
                      color: "#000",
                    }}
                  >
                    {" "}
                    Below are the details :
                  </Typography>
                </Box>
                <Box>
                  <Typography style={{ color: "#626262" }}>
                    Blockchain Name : {networkDetails?.chainName}
                  </Typography>
                  <Typography style={{ color: "#626262" }}>
                    Chain Id : 9731
                  </Typography>
                  <Typography style={{ color: "#626262" }}>
                    RPC URL : {networkDetails?.rpcUrls}{" "}
                    <CopyToClipboard text={networkDetails?.rpcUrls}>
                      <FaRegCopy
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => toast.info("Copied")}
                      />
                    </CopyToClipboard>
                  </Typography>
                  <Typography style={{ color: "#626262" }}>
                    Symbol : {networkDetails?.nativeCurrency?.symbol}
                  </Typography>
                  <Typography style={{ color: "#626262" }}>
                    Explorer : {networkDetails?.blockExplorerUrls}{" "}
                    <CopyToClipboard text={networkDetails?.blockExplorerUrls}>
                      <FaRegCopy
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => toast.info("Copied")}
                      />
                    </CopyToClipboard>
                  </Typography>
                </Box>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </Box>

        <Dialog
          open={open2}
          onClose={handleClose2}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.paper }}
        >
          <DialogContent className={classes.dialogBox}>
            <Grid container spacing={4} alignItems="center">
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box className="imagebox">
                  <img src="images/modalItem.png" alt="" width="100%" />
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Box className={classes.setting}>
                    <img src="images/settings1.png" />
                  </Box>

                  <Box className="modal_text" pt={3}>
                    <Typography variant="body2">
                      <Checkbox
                        style={{ color: "#35a5f5" }}
                        onChange={handleChange}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      Are you sure you are 13+
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ marginBottom: "10px" }}
                    >
                      <Checkbox
                        style={{ color: "#35a5f5" }}
                        onChange={handleChangecheckedTerms}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      I accept the Tarality{" "}
                      <Link
                        target="_blank"
                        to="/terms-conditions"
                        style={{ color: "#039be3", textDecoration: "none" }}
                      >
                        Terms of Services
                      </Link>
                    </Typography>
                    <Button
                      onClick={handleClose2}
                      variant="contained"
                      color="secondary"
                      autoFocus
                    >
                      Cancel
                    </Button>
                    {checked && checkedTerms && user?.walletdata === "BLOCK" ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={walletBlocktoast}
                        autoFocus
                      >
                        Proceed
                      </Button>
                    ) : (
                      <>
                        {checked && checkedTerms && (
                          <>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => onWalletConnectHandler(data)}
                              autoFocus
                            >
                              Proceed
                            </Button>
                          </>

                          // <Button
                          //   variant="contained"
                          //   size="small"
                          //   color="primary"
                          //   onClick={() => onWalletConnectHandler(data)}
                          //   autoFocus
                          // >
                          //   Proceed
                          // </Button>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog
          open={user.errorPop}
          onClose={() => user.setErrorPop(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.paper }}
        >
          <DialogActions>
            <IconButton
              onClick={() => user.setErrorPop(false)}
              className={classes.customizedButton}
            >
              <GiCancel />
            </IconButton>
          </DialogActions>
          <DialogContent className={classes.dialogBox}>
            <Box className="modal_text">
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <ErrorIcon style={{ color: "red" }} />
              </Box>
              <Typography variant="h6" align="center">
                Error
              </Typography>
              <Box style={{ textAlign: "center" }}>
                <Typography variant="body2">{user.errorMsg}</Typography>
                <Typography variant="body2">
                  If the problem persist please{" "}
                  <span style={{ color: "#039be3" }}>Contact support</span>
                </Typography>
                <Button
                  style={{
                    backgroundColor: "#039be3",
                    color: "#fff",
                    borderRadius: "50px",
                    height: "40px",
                    marginTop: "12px",
                    width: "160px",
                  }}
                  onClick={() => {
                    const selectectWalletDetails = SUPPORTED_WALLETS.filter(
                      (data) =>
                        data.name ===
                        window.sessionStorage.getItem("walletName")
                    );
                    onWalletConnectHandler(selectectWalletDetails[0]);
                  }}
                >
                  TRY AGAIN
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
}
