import React from "react";

const Logo = (props) => {
  return (
    <img
      src="/images/logo-Tarality.gif"
      alt="Logo"
      {...props}
      width="100%"
      style={{ width: "100%", maxWidth: "213px" }}
    />
  );
};

export default Logo;
