import React, { useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  TextField,
  FormControl,
  FormHelperText,
  Button,
  InputAdornment,
} from "@material-ui/core";
import axios from "axios";
import { toast } from "react-toastify";

import ApiConfig from "src/ApiConfig/ApiConfig";
import * as yep from "yup";
import { Formik, ErrorMessage, Form } from "formik";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
const useStyles = makeStyles((theme) => ({
  updateSection: {
    padding: "50px 0",
    overflow: "hidden",
    background: "rgba(38, 36, 52, 0.2)",
    boxShadow: "-17px 19px 20px rgb(0 0 0 / 12%)",
    backdropFilter: "blur(15px)",
    position: "relative",
    border: "1px solid #38a8f538",
    [theme.breakpoints.down("xs")]: {
      //   padding: "50px 0",
    },
    "& .shape": {
      position: "absolute",
      top: "20px",
      left: "100px",
    },

    "& .emailBox": {
      position: "relative",
      "& button": {
        color: "#fff",
        right: "0",
        height: "43px",
        position: "absolute",
        margin: "3px 3px 2px 0",
        minWidth: "100px",
        background:
          "linear-gradient(91.94deg, #35A5F5 31.32%, #62D3F0 117.28%)",
        borderRadius: "8px",
      },
    },
    "& input": {
      background: "rgba(255, 255, 255, 0.3)",
      backdropFilter: "blur(15px)",
      borderRadius: "5px",
      border: " none",
      height: "50px",
      padding: "0 112px 0px 10px",
      color: " #fff",
    },
    "& h3": {
      marginLeft: "15px",
      fontSize: "50px",
      fontWeight: "bold",
      color: "#fff",
      "& span": {
        color: "#fff",
        fontWeight: "300",
      },
      "& img": {
        marginRight: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
    },
  },
}));

export default function BestSeller(props) {
  const classes = useStyles();
  const [confirmation, setConfirmation] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);
  const [emailUpdatedata, setEmailUpdatedata] = useState("");

  // const formInitialSchema = {
  //   email: "",
  // };
  // const formValidationSchema = yup.object().shape({
  //   email: yup
  //     .string()
  //     .email("You have entered an invalid email address. Please try again")
  //     // .required("Email address is required")
  //     .matches("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"),
  // });
  return (
    <Box className={classes.updateSection}>
      <Container maxWidth="lg">
        <Typography variant="h3" align="center">
          Subscribe <span>Our Newsletter</span>
        </Typography>
        <Box mt={1} mb={3}>
          <Typography variant="body1" align="center">
            There will be multiple minting rounds hosted on Tarality Metaverse.
            Minting soon.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={4}></Grid>
          <Grid item xs={12} lg={4} sm={4} md={4}>
            <Box className="emailBox">
              <Formik
                initialValues={{
                  email: "",
                  link: "",
                }}
                initialStatus={{
                  success: false,
                  successMsg: "",
                }}
                validationSchema={yep.object().shape({
                  email: yep
                    .string()
                    .required("Email address is required field.")
                    .matches(
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      "Please enter a valid email address"
                    ),
                })}
                onSubmit={async ({ email }) => {
                  setIsUpdating(true);

                  try {
                    // if (userType === "USER") {
                    // axios
                    //   .put(ApiConfig.userSubscribe, {
                    //     params: {
                    //       email,
                    //       link: "http://localhost:3000/subscribehovr",
                    //     },
                    //   })
                    //   .then(async (response) => {
                    axios({
                      method: "PUT",
                      url: ApiConfig.userSubscribe,

                      params: {
                        email,
                        // link: "http://localhost:3000/subscribehovr",
                        link: "https://metaverse-marketplace.mobiloitte.org/subscribehovr",
                        // link: "http://full-blockchain.mobiloitte.org/subscribehovr",
                      },
                    })
                      .then(async (response) => {
                        if (response.data.statusCode === 200) {
                          setEmailUpdatedata(response.data.result);

                          //   toast.warn(response.data.response_message);
                          toast.success("We have sent an email. please verify");
                          setIsUpdating(false);
                        } else if (response.statusCode === 409) {
                          toast.success(response.data.responseMessage);
                          setIsUpdating(false);
                        } else {
                          setIsUpdating(false);
                          toast.success("We have sent email please verify");
                          setConfirmation(true);
                        }
                      })
                      .catch((error) => {
                        console.log(error.message);
                        if (error.response) {
                          toast.error(error.response.data.responseMessage);
                        }
                        setIsUpdating(false);
                      });
                    // }
                  } catch (err) {
                    console.error(err.response);
                    //  setIsLoading(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        name="email"
                        inputProps={{ maxLength: 60 }}
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="outlined-basic"
                        placeholder="Enter your email"
                        fullWidth
                      />
                      <FormHelperText error>
                        {touched.email && errors.email}
                      </FormHelperText>
                    </FormControl>
                    <Button
                      type="submit"
                      className="searchBtn"
                      disabled={isUpdating}
                    >
                      Subscribe {isUpdating && <ButtonCircularProgress />}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Box>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
        <Box></Box>
        {/* <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ zIndex: "1111" }}
        >
          <Grid item xs={12} sm={6} align="center">
            <Typography variant="h3">GET THE LATEST UPDATES</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className="emailBox">
              <Formik
                initialValues={{
                  email: "",
                  link: "",
                }}
                initialStatus={{
                  success: false,
                  successMsg: "",
                }}
                validationSchema={yep.object().shape({
                  email: yep
                    .string()
                    .required("Email address is required field.")
                    .matches(
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      "Please enter a valid email address"
                    ),
                })}
                onSubmit={async ({ email }) => {
                  setIsUpdating(true);

                  try {
                    // if (userType === "USER") {
                    // axios
                    //   .put(ApiConfig.userSubscribe, {
                    //     params: {
                    //       email,
                    //       link: "http://localhost:3000/subscribehovr",
                    //     },
                    //   })
                    //   .then(async (response) => {
                    axios({
                      method: "PUT",
                      url: ApiConfig.userSubscribe,

                      params: {
                        email,
                        // link: "http://localhost:3000/subscribehovr",
                        link: "https://hovr.site/subscribehovr",
                        // link: "http://full-blockchain.mobiloitte.org/subscribehovr",
                      },
                    })
                      .then(async (response) => {
                        if (response.data.statusCode === 200) {
                          setEmailUpdatedata(response.data.result);

                          //   toast.warn(response.data.response_message);
                          toast.success("We have sent an email. please verify");
                          setIsUpdating(false);
                        } else if (response.statusCode === 409) {
                          toast.success(response.data.responseMessage);
                          setIsUpdating(false);
                        } else {
                          setIsUpdating(false);
                          toast.success("We have sent email please verify");
                          setConfirmation(true);
                        }
                      })
                      .catch((error) => {
                        console.log(error.message);
                        if (error.response) {
                          toast.error(error.response.data.responseMessage);
                        }
                        setIsUpdating(false);
                      });
                    // }
                  } catch (err) {
                    console.error(err.response);
                    //  setIsLoading(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        name="email"
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="outlined-basic"
                        placeholder="Enter your email"
                        fullWidth
                      />
                      <FormHelperText error>
                        {touched.email && errors.email}
                      </FormHelperText>
                    </FormControl>
                    <Button
                      type="submit"
                      className="searchBtn"
                      disabled={isUpdating}
                    >
                      I’m In {isUpdating && <ButtonCircularProgress />}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid> */}
      </Container>
    </Box>
  );
}
