import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  Paper,
  Typography,
  Box,
  Button,
  IconButton,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import { useWeb3React } from "@web3-react/core";
import { UserContext } from "src/context/User";
import Apiconfig from "src/ApiConfig/ApiConfig";
import axios from "axios";
import GavelIcon from "@material-ui/icons/Gavel";
import moment from "moment";
import { GiCancel } from "react-icons/gi";
import { sortAddress, calculateTimeLeft } from "src/utils";
import ShareSocialMedia from "src/component/ShareSocialMedia";
import { toast } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";
import { ChainIDEthereum } from "src/constants";
const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #16032999",
    cursor: "pointer",
    margin: "0 5px",
    overflow: "hidden",
    position: "relative",
    background: "rgba(38, 36, 52, 0.3)",
    boxShadow: "0px 7px 12px rgb(0 0 0 / 15%)",
    borderRadius: "10px",
    backdropFilter: "blur(31.3725px)",
    "&:hover": {
      // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      // filter: "drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.25))",
      // background: "#fff",
    },
    "& .basecontent": {
      "& .databox": {
        // borderBottom: "1px dashed rgba(0, 0, 0, 0.5)",
        paddingBottom: "16px",
      },
      "& .buttonbox": {
        // paddingTop: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: "rgba(210, 210, 210, 0.05)",
        border: "1.5px solid rgba(255, 255, 255, 0.04)",
        backdropFilter: "blur(7.5px)",
        borderRadius: "8px",
        padding: "7px",
        "@media(max-width:1430px)": {
          display: "block",
        },
        "@media(max-width:1272px)": {
          display: "flex",
        },
        "& .smallbtnbox": {
          paddingTop: "10px",
          "& button": {
            width: "100%",
          },
        },
      },
    },
  },
  text: {
    whiteSpace: "pre",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "calc(100% - 5px)",
    color: "#fff",
  },
  mainimg: {
    width: "100%",
    height: "190px ",
    overflow: "hidden",
    backgroundPosition: "center !important",
    backgroundSize: "cover !important",
    backgroundRepeat: " no-repeat !important",
    borderRadius: "5px 5px 0px 0px",
    backgroundColor: "#ccc !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    zIndex: "-1",
    "& .topcontent": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "15px",
      "& .topleft": {
        display: "flex",
        alignItems: "center",
        background: "#FFFFFf",
        borderRadius: "10px",
        padding: "5px 8px",
        width: "fit-content",
        "& p": {
          marginLeft: "5px",
          color: "#4da7f0",
          [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
          },
          "& span": {
            color: "#000000",
          },
        },
        "& .Userbox": {
          display: "flex",
          alignItems: "center",
          "& figure": {
            margin: "0",
            marginLeft: "-10px",
            height: "25px",
            width: "25px",
            borderRadius: "50%",
            overflow: "hidden",
            backgroundColor: "#101010",
            position: "relative",
            transition: "0.3s",
            cursor: "pointer",
            "&:first-child": {
              marginLeft: "0px",
            },
            "&:hover": {
              zIndex: "2",
              transform: "scale(1.2)",
            },
            "& img": {
              width: "auto",
              maxWidth: "100%",
              maxHeight: "41px",
            },
          },
        },
      },
      "& .likes": {
        display: "flex",
        alignItems: "center",
        background: "#FFFFFF",
        borderRadius: "10px",
        width: "fit-content",
        padding: "5px 8px",
        "& p": {
          marginLeft: "5px",
          color: "#fff",
        },
      },
    },
    "& .bottomcontent": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "10px",
      "& .timer": {
        background: "rgb(148, 18, 117)",
        padding: "6px",
        width: "-webkit-fill-available",
        textAlign: "center",
        position: "absolute",
        // display: "flex",
        // alignItems: "center",
        // width: "fit-content",
        // background:
        //   "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
        // border: "1px dashed #FFFFFF",
        // filter: "drop-shadow(0px 0px 53px rgba(0, 0, 0, 0.25))",
        // backdropFilter: "blur(42px)",
        // borderRadius: "10px",
        // padding: "5px 10px",
        "& h6": {
          color: "#FFFFFF",
        },
      },
    },
  },

  customizedButton: {
    position: "absolute",
    top: "-42px",
    right: "-9px",
    color: "#fff",
  },

  mainimgCreater: {
    overflow: "hidden",
    height: "100%",
    backgroundPosition: "center !important",
    backgroundSize: "cover !important",
    backgroundRepeat: " no-repeat !important",
    borderRadius: "5px 5px 0px 0px",
    // backgroundColor: "#ccc !important",
    padding: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    zIndex: "-1",
    "& .topleft": {
      display: "flex",
      alignItems: "center",
      // background: "#FFFFFF",
      borderRadius: "10px",
      // padding: "5px 8px",
      width: "fit-content",
      "& p": {
        marginLeft: "5px",
        color: "#4da7f0",
        [theme.breakpoints.down("xs")]: {
          fontSize: "10px",
        },
        "& span": {
          color: "#000000",
        },
      },
      "& .Userbox": {
        display: "flex",
        alignItems: "center",
        "& figure": {
          width: "40px",
          cursor: "pointer",
          height: "40px",
          margin: "0",
          overflow: "hidden",
          position: "relative",
          transition: "0.3s",
          marginLeft: "-10px",
          borderRadius: "50%",
          backgroundColor: "#101010",
          border: "2px solid#000",
          "&:first-child": {
            marginLeft: "0px",
          },
          "&:hover": {
            zIndex: "2",
            transform: "scale(1.2)",
          },
          "& img": {
            width: "auto",
            maxWidth: "100%",
            height: "40px",
          },
        },
      },
    },
  },
}));

function AllNftCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const { data, type, callbackFun } = props;
  const { account, library, chainId } = useWeb3React();

  const [open, setOpen] = useState(false);
  const user = useContext(UserContext);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const updateDimensions = () => {
    var offsetWidth = document.getElementById(
      "imagecard" + (data?._id ? data?._id : data?.nftId?._id)
    )?.offsetWidth;
    var newoofsetWidth = offsetWidth - 80;
    document.getElementById(
      "imagecard" + (data?._id ? data?._id : data?.nftId?._id)
    ).style.height = newoofsetWidth + "px";
  };
  useEffect(() => {
    updateDimensions();
  }, [data, data?._id ? data?._id : data?.nftId?._id]);
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const likeDislikeNftHandler = async (id) => {
    if (user.isLogin && id) {
      try {
        const res = await axios.get(Apiconfig.likeDislikeOrder + id, {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        });
        if (res.data.statusCode === 200) {
          toast.success(res.data.responseMessage);
          if (callbackFun) {
            callbackFun();
          }
        } else {
          toast.warn(res.data.responseMessage);
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.warn("Please connect your wallet");
    }
  };

  if (user.userData && data?.likesUsers) {
    var likesUsers = data?.likesUsers?.filter(
      (order) => order === user.userData._id
    );

    var isLike = likesUsers?.length > 0;
  }

  const [favourite, setisfavourite] = useState([]);

  const favouriteNftHandler = async (id) => {
    if (user.isLogin && id) {
      try {
        const res = await axios.get(Apiconfig.favouriteUnFavouriteOrder + id, {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        });
        if (res.data.statusCode === 200) {
          toast.success(res.data.responseMessage);
          if (callbackFun) {
            callbackFun(id);
          }
        } else {
          toast.warn(res.data.responseMessage);
        }
        // if (res.data.statusCode === 200) {
        //   toast.success(res.data.responseMessage);
        //   if (callbackFun) {
        //     callbackFun();
        //   }
        // } else {
        //   toast.warn(res.data.responseMessage);
        // }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.warn("Please connect your wallet");
    }
  };

  if (user.userData && data?.favouriteUsers) {
    var favouriteUsers = data?.favouriteUsers?.filter(
      (order) => order === user.userData._id
    );
    var isfavourite = favouriteUsers?.length > 0;
  }

  const expirytime = data?.expiryTime
    ? data?.expiryTime
    : data?.orderId?.expiryTime
    ? data?.orderId?.expiryTime
    : null;

  // console.log("data?.orderId?.expiryTime",data?.orderId?.expiryTime)
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(new Date(parseInt(expirytime))));
    }, 1000);
    return () => clearTimeout(timer);
  });
  // : { background: "url(" + "images/market_detail.png" + ")" }

  const isPrivate =
    data?.itemCategory?.trim() === "private documents"
      ? data?.nftId?.recipientWalletAddress === account ||
        data?.nftId?.recipientBackupWalletAddress === account ||
        data?.userId?.walletAddress === account
        ? true
        : false
      : true;

  return (
    <>
      <Paper className={classes.root}>
        <Box
          onClick={() => {
            history.push({
              pathname: "/marketplace-Detail",
              search: data._id,
              state: {
                data: data,
              },
            });
          }}
          id={`imagecard${data?._id ? data?._id : data?.nftId?._id}`}
          className={classes.mainimg}
          style={
            // isPrivate
            data?.nftId?.coverImage
              ? { background: "url(" + data?.nftId?.coverImage + ")" }
              : data?.orderId?.nftId?.coverImage
              ? { background: "url(" + data?.orderId?.nftId?.coverImage + ")" }
              : { background: "url(" + "images/privateimage.jpeg" + ")" }
          }
        >
          <Box className="topcontent">
            {data?.nftId?.mediaType === "video" && (
              <Box style={{ position: "absolute", right: "20px", top: "10px" }}>
                <PlayCircleOutlineIcon
                  onClick={() => {
                    history.push({
                      pathname: "/marketplace-Detail",
                      search: data._id,
                    });
                  }}
                  style={{ cursor: "pointer", color: "white" }}
                />
              </Box>
            )}
            {data?.nftId?.mediaType === "audio" && (
              <Box style={{ position: "absolute", right: "20px", top: "10px" }}>
                <AudiotrackIcon
                  onClick={() => {
                    history.push({
                      pathname: "/marketplace-Detail",
                      search: data._id,
                    });
                  }}
                  style={{ cursor: "pointer", color: "white" }}
                />
              </Box>
            )}
          </Box>

          {data?.nftId?.isPlace && data?.saleType === "ONSALE" ? (
            <Box className="bottomcontent">
              <Box className="timer">
                {parseFloat(expirytime) < moment().unix() || !expirytime ? (
                  <Typography variant="h4">Expired</Typography>
                ) : (
                  <Typography variant="h6" className={classes.text}>
                    {timeLeft.days ? timeLeft.days && timeLeft.days : "0"}d :
                    {timeLeft.hours ? timeLeft.hours && timeLeft.hours : "0"}h :
                    {timeLeft.minutes
                      ? timeLeft.minutes && timeLeft.minutes
                      : "0"}
                    m :{" "}
                    {timeLeft.seconds
                      ? timeLeft.seconds && timeLeft.seconds
                      : "0"}
                    s
                  </Typography>
                )}
                <Box pl={1}>{/* <img src="images/Flame.png" /> */}</Box>
              </Box>
            </Box>
          ) : (
            <Box className="bottomcontent">
              <Box className="timer">
                <Typography variant="h6">
                  Sold To{" "}
                  {data?.buyerId?.name
                    ? data?.buyerId?.name
                    : sortAddress(data?.buyerId?.walletAddress)}{" "}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box className="basecontent">
          <Box p={2}>
            <Box className="databox">
              <Grid container spacing={1}>
                <Grid item xs={6} sm={6} align="left">
                  <Typography variant="h6" className={classes.text}>
                    {data?.tokenName ? data?.tokenName : data?.nftId?.tokenName}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} align="right">
                  <Typography variant="body1" className={classes.text}>
                    {data.stock}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} align="left">
                  <Typography
                    variant="body2"
                    style={{ color: "rgba(255, 255, 255, 0.6)" }}
                  >
                    <span style={{ color: "#EC13B7" }}>From </span>{" "}
                    {data?.price}{" "}
                    {data?.network == ChainIDEthereum ? "ETH" : "BNB"}/Bid{" "}
                    {data?.bidId[0]?.price ? data?.bidId[0]?.price : "0"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} align="left">
                  <Box className={classes.mainimgCreater}>
                    <Box className="topcontent">
                      <Box className="topleft">
                        <Box className="Userbox">
                          <Tooltip
                            title={`Created by : ${
                              data.nftId?.userId?.name
                                ? data.nftId?.userId?.name
                                : sortAddress(data.nftId?.userId?.walletAddress)
                            }`}
                            placement="top-start"
                          >
                            <figure
                              onClick={() => {
                                history.push({
                                  pathname: "/author",
                                  search: data?.nftId?.userId?._id,
                                  state: { data: data },
                                });
                              }}
                            >
                              <img
                                src={
                                  data.nftId?.userId?.profilePic
                                    ? data.nftId?.userId?.profilePic
                                    : "images/Ellipse1.png"
                                }
                              />
                            </figure>
                          </Tooltip>
                          <Tooltip
                            title={`Collection : ${data.collectionId?.displayName}`}
                            placement="top-start"
                          >
                            <figure
                              onClick={() => {
                                history.push({
                                  pathname: "/collection-details",
                                  search: data?.collectionId?._id,
                                  state: { data: data },
                                });
                              }}
                            >
                              <img
                                src={
                                  data.collectionId?.collectionImage
                                    ? data.collectionId?.collectionImage
                                    : "images/Ellipse1.png"
                                }
                              />
                            </figure>
                          </Tooltip>
                        </Box>
                        <Typography variant="body2" style={{ color: "#fff" }}>
                          Create by{" "}
                          <span style={{ color: "#00FBFB" }}>
                            {data.nftId?.userId?.name
                              ? data.nftId?.userId?.name
                              : sortAddress(
                                  data.nftId?.userId?.walletAddress
                                )}{" "}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className="buttonbox">
              <Box>
                <Grid container spacing={2}>
                  <Grid container spacing={2} style={{ paddingLeft: "10px" }}>
                    <Grid item xs={4} align="right">
                      <IconButton
                        style={{ background: "#68676761", padding: "10px" }}
                      >
                        <img
                          src={
                            data?.network == ChainIDEthereum
                              ? "/images/ethereum.png"
                              : "images/binance.png"
                          }
                          alt="image"
                          style={{ height: "20px" }}
                        />
                      </IconButton>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant="body2"
                        style={{ color: "rgba(255, 255, 255, 0.87)" }}
                      >
                        Current Bid
                      </Typography>
                      <Typography variant="body2" className={classes.text}>
                        <GavelIcon style={{ fontSize: "14px" }} />
                        &nbsp;
                        {data?.bidId[0]?.price ? data?.bidId[0]?.price : "0"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box className="smallbtnbox">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => {
                    history.push({
                      pathname: "/marketplace-Detail",
                      search: data._id,
                      state: {
                        data: data,
                      },
                    });
                  }}
                >
                  Details
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogActions>
            <IconButton
              onClick={() => setOpen(false)}
              className={classes.customizedButton}
            >
              <GiCancel />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <Box className={classes.sharemodal} mb={2} align="center" mt={3}>
              <ShareSocialMedia url={window.location} />
            </Box>
          </DialogContent>
        </Dialog>
      </Paper>
    </>
  );
}

export default AllNftCard;
