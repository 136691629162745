import React, { useState, useRef, useContext, useEffect } from "react";

import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  ListItem,
  List,
  Link,
  IconButton,
} from "@material-ui/core";
import { UserContext } from "src/context/User";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import { FaFacebookF, FaInstagram, FaMediumM } from "react-icons/fa";
import { FiSend, FiTwitter, FiYoutube } from "react-icons/fi";
import { AiOutlineReddit, AiFillYoutube } from "react-icons/ai";
import { FaPinterestP } from "react-icons/fa";
import { FcReddit } from "react-icons/fc";
import Scroll from "react-scroll";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import Update from "./Update";
import Profile from "../../views/pages/Profile/Profile";
const ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  footerSection: {
    background: "#000",
    position: "relative",
    // padding: "50px 0px 0",
    zIndex: "2",
    overflow: " hidden",
    backgroundImage: "url('images/Footer background.png')",
    "& .iconBox": {
      background: "linear-gradient(261.87deg, #62D3F0 13.12%, #35A5F5 83.57%)",
      margin: "5px",
    },
    "& .copy": {
      borderTop: "1px solid #d0d0d061",
      padding: "10px 0",
      textAlign: "center",
      fontWeight: 300,
      fontSize: "12px",
      color: "#626262",
    },
    "& .shape": {
      position: "absolute",
      right: "20px",
      top: "50px",
      [theme.breakpoints.down("xs")]: {
        top: "50%",
      },
    },
    "& .shape2": {
      position: "absolute",
      left: "80px",
      top: "55px",
    },
    "& .shape3": {
      position: "absolute",
      left: "40px",
      top: "75px",
    },
    "& .shape4": {
      position: "absolute",
      left: "200px",
      bottom: "50px",
    },
    "& ul": {
      paddingLeft: "0",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "0px",
      },
      "& li": {
        paddingLeft: "0",
        alignItems: "center",
        color: " #fff !important",
        fontSize: "14px",
        "& svg": {
          marginRight: "10px",
          color: "#408FAC",
          fontSize: "15px",
        },
      },
    },
    "& svg": {
      color: "#fff",
      fontSize: "15px",
    },
    "& p": {
      color: "#fff",
    },
    "& h6": {
      color: "#fff",
      [theme.breakpoints.down("sm")]: {
        marginTop: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "20px",
      },
    },
    "& a": {
      color: "#fff",
      fontWeight: 400,
      textDecoration: "none",
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      "&:hover": {
        color: "#35a5f5",
        textDecoration: "none",
      },
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const history = useHistory();
  const user = useContext(UserContext);

  const { account, library, chainId } = useWeb3React();

  const toastmsg = () => {
    toast.warn("Please connect your wallet");
  };
  const toastmsgcreate = () => {
    toast.warn("Please connect your wallet");
  };
  return (
    <>
      <Box className={classes.footerSection}>
        <Update />
        <Box mt={7} mb={4}>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={12} sm={12} md={3}>
                <Box mr={8}>
                  <Box mb={2}>
                    {" "}
                    <RouterLink to="/">
                      <img src="images/logo.png" /> <br />
                    </RouterLink>
                  </Box>
                  <Typography variant="body1" component="small">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    galley of type and scrambled it to make a type specimen
                    book.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={3}>
                    <Typography variant="h6">Web Link</Typography>
                    <List>
                      <ListItem to="/collections" component={RouterLink}>
                        Collections
                      </ListItem>

                      {/* <ListItem to="/ranking" component={RouterLink}>
                      Ranking
                    </ListItem> */}
                      {account ? (
                        <ListItem to="/activity" component={RouterLink}>
                          My Activity
                        </ListItem>
                      ) : (
                        <ListItem
                          onClick={() => {
                            toast.warn("Please connect wallet");
                          }}
                          style={{ cursor: "pointer", color: "#646464" }}
                        >
                          My Activity
                        </ListItem>
                      )}
                      {/* <ListItem to="/activity" component={RouterLink}>
                        My Activity
                      </ListItem> */}
                      <ListItem style={{ cursor: "pointer" }}>
                        <Link>Referral Program</Link>
                      </ListItem>
                      {/* <ListItem to="/support-tickets" component={RouterLink}>
                      Support-Ticket
                    </ListItem> */}
                    </List>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Typography variant="h6">My Account</Typography>
                    <List>
                      {/* {account && user?.userData?.userType === "Admin" ? (
                        <ListItem to="my-mints" component={RouterLink}>
                          My Mint
                        </ListItem>
                      ) : (
                        <ListItem
                          // onClick={() => {
                          //   history.push({
                          //     pathname: "conect-wallet",
                          //     search: "mymints",
                          //   });
                          // }}
                          onClick={() => {
                            toast.warn("Please connect admin wallet");
                          }}
                          style={{ cursor: "pointer", color: "#646464" }}
                        >
                          My Mint
                        </ListItem>
                      )} */}

                      {account ? (
                        <ListItem to="create" component={RouterLink}>
                          Create Items
                        </ListItem>
                      ) : (
                        <ListItem
                          onClick={() => {
                            toast.warn("Please connect wallet");
                          }}
                          style={{ cursor: "pointer", color: "#646464" }}
                        >
                          Create Items
                        </ListItem>
                      )}
                      {/* <ListItem to="create" component={RouterLink}>
                        Create Items
                      </ListItem> */}
                      {/* <ListItem to="profile" component={RouterLink}>
                        My Account
                      </ListItem> */}
                      {account ? (
                        <ListItem to="profile" component={RouterLink}>
                          My Account
                        </ListItem>
                      ) : (
                        <ListItem
                          onClick={() => {
                            toast.warn("Please connect wallet");
                          }}
                          style={{ cursor: "pointer", color: "#646464" }}
                          // onClick={toastmsg}
                        >
                          My Account
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Typography variant="h6">Support</Typography>
                    <List>
                      {/* <ListItem>
                      {history.location.pathname !== "/" ? (
                        <List>
                          <ListItem>
                            <Link
                              to="/"
                              style={{
                                textDecoration: "none",
                                color: "#aeaeae",
                              }}
                            >
                              FAQs{" "}
                            </Link>
                          </ListItem>
                        </List>
                      ) : (
                        <List>
                          <ListItem>
                            <ScrollLink
                              smooth={true}
                              duration={500}
                              style={{
                                textDecoration: "none ",
                                cursor: "pointer",
                              }}
                              to="section1"
                            >
                              FAQs{" "}
                            </ScrollLink>
                          </ListItem>
                        </List>
                      )}
                    </ListItem> */}
                      <ListItem to="/faqs" component={RouterLink}>
                        FAQs
                      </ListItem>
                      <ListItem to="/help-center" component={RouterLink}>
                        Help Center
                      </ListItem>
                      <ListItem to="/feedback" component={RouterLink}>
                        Feedback
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Typography variant="h6">Other</Typography>
                    <List>
                      <ListItem to="/about" component={RouterLink}>
                        About Us
                      </ListItem>
                      <ListItem to="/terms-conditions" component={RouterLink}>
                        Terms & Conditions
                      </ListItem>
                      <ListItem to="/privacy-policy" component={RouterLink}>
                        Privacy Policy
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <Typography variant="h6">Contact Us</Typography>
                <List>
                  <ListItem>
                    <Link href="mailto:info@hovr.site">
                      <AiOutlineMail />
                      {/* {user?.userData?.userType === "Admin" ? (
                      <> {user?.userData?.email}</>
                    ) : (
                      <>support@HOVR.com</>
                    )} */}
                      info@metaverse.site
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="mailto:info@hovr.site">
                      <AiOutlineMail />
                      {/* {user?.userData?.userType === "Admin" ? (
                      <> {user?.userData?.email}</>
                    ) : (
                      <>support@HOVR.com</>
                    )} */}
                      info@metaverse.site
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="mailto:info@hovr.site">
                      <AiOutlineMail />
                      {/* {user?.userData?.userType === "Admin" ? (
                      <> {user?.userData?.email}</>
                    ) : (
                      <>support@HOVR.com</>
                    )} */}
                      info@metaverse.site
                    </Link>
                  </ListItem>
                </List>

                <Box>
                  <IconButton className="iconBox">
                    <Link target="_blank" href="https://www.facebook.com">
                      <FaFacebookF />
                    </Link>
                  </IconButton>

                  <IconButton className="iconBox">
                    <Link target="_blank" href="https://www.instagram.com">
                      <FaInstagram />
                    </Link>
                  </IconButton>
                  <IconButton className="iconBox">
                    <Link target="_blank" href="https://twitter.com">
                      <FiTwitter />
                    </Link>
                  </IconButton>
                  <IconButton className="iconBox">
                    <Link target="_blank" href="https://in.pinterest.com/">
                      <FaPinterestP />
                    </Link>
                  </IconButton>
                  {/* <IconButton style={{ background: "linear-gradient(261.87deg, #62D3F0 13.12%, #35A5F5 83.57%)",}}>
                  <Link
                    target="_blank"
                    href="https://www.reddit.com/r/hovrNFT/"
                  >
                    <AiOutlineReddit style={{ fontSize: "20px" }} />
                  </Link>
                </IconButton> */}
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Box className="copy" mt={1}>
            © 2022 All Rights Reserved
          </Box>
        </Box>
      </Box>
    </>
  );
}
