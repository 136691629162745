import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import Footer from "./Footer";
import TopBar from "./TopBar";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#000",
  },
  MainLayout: {
    zIndex: "1",
    position: "relative",
    minHeight: "calc(100vh - 415px)",
    // backgroundColor: "#000",
  },
  shade: {
    top: "22%",
    left: "auto",
    right: "0",
    width: "600px",
    bottom: "auto",
    filter: "blur(100px)",
    height: "600px",
    opacity: "0.55",
    zIndex: "-1",
    position: "fixed",
    borderRadius: "1000px",
    backgroundImage:
      "linear-gradient(162.34deg, rgba(0, 251, 251, 0.5) 57.54%, rgba(46, 30, 219, 0.5) 85.06%)",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    display: "none",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div className={classes.root}>
      <TopBar />
      <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>

      <div className={classes.MainLayout}>
        {" "}
        <Box className={classes.shade}></Box>
        {/* <Box className={classes.shade1}></Box> */}
        {children}
      </div>

      <Footer />
    </div>
  );
};

export default MainLayout;
