import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Typography,
  Menu,
} from "@material-ui/core";
import WalletCard from "src/component/WalletCard";
import { SUPPORTED_WALLETS } from "src/connectors";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "./../../component/Logo";
import { NavLink } from "react-router-dom";
import { ACTIVE_NETWORK, getNetworkDetails } from "src/constants";
import { UserContext } from "src/context/User";
import { useWeb3React } from "@web3-react/core";
import { sortAddress } from "src/utils";
import SearchBox from "src/layouts/HomeLayout/DashboardLayout/SearchBox";
import { BsFillCaretDownFill } from "react-icons/bs";
import { ChainIDBinance, ChainIDEthereum } from "src/constants";
const headersData = [
  {
    label: "Marketplace",
    href: "/explore",
  },
  {
    label: "Collections",
    href: "/collections",
  },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "300",
    borderRadius: 0,
    minWidth: "auto",
    color: "#fff",
    height: "31px",
    padding: "0px 7px",
    letterSpacing: "1px",
    // marginLeft: "15px",
    textDecoration: " none",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {
      color: "#51ACED",
    },
    "&:hover": {
      color: "#51ACED",
    },
  },
  toolbar: {
    paddingLeft: "20px",
    paddingRight: "14px",
    background: "rgba(166, 162, 162, 0.2)",
    border: "1.5px solid rgba(255, 255, 255, 0.1)",
    boxShadow: "0px 8px 15px rgb(0 0 0 / 15%)",
    borderRadius: "10px",
    marginTop: "15px",
  },

  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "140px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px 20px 20px",
    height: "100%",
    background: "#000",
    boxShadow: "-17px 19px 20px rgb(0 0 0 / 12%)",
    backdropFilter: "blur(25px)",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "& .MuiButton-root:hover": {
      // background: "linear-gradient(104.47deg, #00FBFB -6.14%, #2E1EDB 134.27%)",
      color: "red",
      // width: "100%",
      // display: "flex",
      // paddingLeft: "10px",
      // justifyContent: "flex-start",
      backgroundColor: "transparent",
    },
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  menuMobile: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    paddingLeft: 0,
    // borderBottom: "1px solid #3e3e3e",
    padding: "16px",
    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    color: "#fff",
    width: "100%",
    height: "40px",
    position: "relative",
    marginLeft: "20px",
    borderRadius: "8px",
    background: "rgba(255, 255, 255, 0.3)",
    backdropFilter: "blur(15px)",
    overflow: "hidden",

    // [theme.breakpoints.up("sm")]: {
    //   marginLeft: theme.spacing(1),
    //   width: "180px",
    // },
  },
  metamaskhead: {
    display: "flex",
    alignItems: "center",
    background: "#fff",
    borderRadius: "10px",
    padding: "20px",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "24px",
    "&:hover": {
      backgroundColor: "#daf4ff",
    },
    "& img": {
      width: "70px",
      marginRight: "20px",
      [theme.breakpoints.down("xs")]: {
        width: "50px",
      },
    },
    "& h5": {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "20px",
      textTransform: "capitalize",
      color: "#db7d2a",
    },
  },
  searchIcon: {
    color: "#656565",
    height: "100%",
    display: "flex",
    padding: "0px 8px",
    position: "absolute",
    fontSize: "16px",
    alignItems: "center",
    pointerEvents: "none",
    justifyContent: "center",
    background: "rgba(255, 255, 255, 0.4)",
    backdropFilter: "blur(20px)",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  menuButton1: {
    paddingLeft: "0",
  },
  searcBox: {
    backgroundColor: "#DAF4FF",
    // border: "1px solid #daf4ff",
    borderRadius: " 50px",
  },
  menuMobile1: {
    // marginLeft: "10px",
    // backgroundColor: " #FCF2FA",
    // borderRadius: "40px",
    "& h4": {
      fontSize: "14px",
      lineHeight: " 17px",
      color: "#fff",
      margin: "0 5px",
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "& svg": {
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    // "&:hover": {
    //   backgroundColor: " #FCF2FA",
    //   borderRadius: "40px",
    // },
    "& figure": {
      margin: 0,
      width: 30,
      height: 30,
      borderRadius: "50px",
      overflow: "hidden",
      display: "flex",
      justifyContent: " center",
      alignItems: "center",
      "& img": {
        width: "auto",
        height: "auto",
        maxWidth: "100%",
        // maxHeight: "100%",
      },
    },
  },
  flexbox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

export default function Header() {
  const { account, chainId } = useWeb3React();
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const user = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const searchTextRef = React.useRef(null);
  const location = useLocation();
  const [networkDetails, setNetworkDetails] = React.useState([]);
  const [open2, setOpen2] = React.useState(false);
  const [updatepage, setUpdatepage] = useState();
  const [open, setOpen] = React.useState(false);
  const [selectedWallet, setSelectedWallet] = useState();
  const [isOpenNetwork, setIsOpenNetwork] = useState(false);
  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const [isLoaderwallet, setIsloader] = useState(false);

  const onWalletConnectHandler = async (connector) => {
    await user.connectWallet(connector);
    setOpen2(false);
    setIsloader(true);
  };

  useEffect(() => {
    if (account) {
      setIsloader(false);

      handleClose();
    }
  }, [account]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose4 = () => {
    setAnchorEl1(null);
  };
  const handleClose5 = () => {
    setAnchorEl2(null);
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const {
    menuMobile,
    menuButton,
    menuButton1,
    divstake,
    toolbar,
    drawerContainer,
    menuMobile1,
    drawericon,
    logoDrawer,
    containerHeight,
    mainHeader,
    search,
    metamaskhead,
    flexbox,
    inputInput,
    searchIcon,
    inputRoot,
    searcBox,
    marginbtn,
  } = useStyles();
  const history = useHistory();

  const [updateMinSatkeOpen, setUpdateMinSatkeOpen] = useState(false);
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const addNetworkHandler = async () => {
    try {
      const NetworkDetails = getNetworkDetails(ACTIVE_NETWORK);
      // console.log("NetworkDetails34", NetworkDetails);
      setNetworkDetails(NetworkDetails[0]);
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  useEffect(() => {
    addNetworkHandler();
  }, []);

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const StyledMenu = withStyles({
    paper: {
      marginTop: "2px",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  useEffect(() => {
    if (location.search.substring(1, location.search.length)) {
      const id = location.search.substring(1, location.search.length);

      setUpdatepage(id);
    }
  }, [location.search]);

  const displayDesktop = () => {
    return (
      // <Container maxWidth="lg" className="p-0">
      <Toolbar className={toolbar}>
        <Grid container alignItems="center">
          <Grid item xs={3}>
            {femmecubatorLogo}
          </Grid>
          <Grid item xs={3}>
            {SearchBoxx}
          </Grid>
          <Grid item xs={6} align="right">
            {getMenuButtons()}
          </Grid>
        </Grid>

        <div>
          {user?.isLogin ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              paddingRight="25px"
              paddingLeft="25px"
            >
              <IconButton
                aria-label="delete"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick1}
                className={menuMobile1}
                style={{ margin: "0px 10px" }}
                size="small"
              >
                <figure
                  style={
                    user?.isLogin
                      ? { height: "40px", width: "40px" }
                      : { width: "0px" }
                  }
                >
                  <img
                    src={
                      user?.userData?.profilePic
                        ? user?.userData?.profilePic
                        : "/images/idicon.svg"
                    }
                    alt=""
                  />
                </figure>
                <Typography
                  variant="h4"
                  title={
                    user?.userData?.name
                      ? user?.userData?.name
                      : user?.userData?.walletAddress
                  }
                >
                  {" "}
                  {user?.userData?.name
                    ? user?.userData?.name.slice(0, 5) + ".."
                    : sortAddress(user?.userData?.walletAddress)}
                </Typography>
                <BsFillCaretDownFill
                  style={{ color: "#fff", fontSize: "16px" }}
                />
              </IconButton>
              <IconButton
                aria-label="delete"
                aria-controls="simple-menu"
                aria-haspopup="true"
                size="small"
                className={menuMobile1}
                onClick={handleClick2}
              >
                <figure>
                  <img
                    src={
                      chainId === ChainIDBinance
                        ? "/images/binance.png"
                        : "/images/ethereum.png"
                    }
                    alt=""
                  />
                </figure>
                <Typography variant="h4">
                  {chainId === ChainIDBinance ? "BSC Smart Chain" : "Ethereum"}
                </Typography>

                <BsFillCaretDownFill
                  style={{ color: "#fff", fontSize: "16px" }}
                />
              </IconButton>
            </Box>
          ) : (
            <IconButton
              aria-label="delete"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={() => setUpdateMinSatkeOpen(true)}
              className={menuMobile1}
              size="small"
            >
              <figure
                style={
                  user?.isLogin
                    ? { height: "40px", width: "40px" }
                    : { width: "0px" }
                }
              >
                <img
                  src={
                    user?.userData?.profilePic
                      ? user?.userData?.profilePic
                      : "/images/idicon.svg"
                  }
                  alt=""
                />
              </figure>
              <Button
                variant="contained"
                size="large"
                color="primary"
                // to="/conect-wallet"
                // component={Link}
                onClick={handleClickOpen}
                style={{ marginLeft: "15px", whiteSpace: "pre" }}
              >
                Connect Wallet
              </Button>
            </IconButton>
          )}

          <Box className={divstake}>
            <StyledMenu
              id="simple-menu"
              disableScrollLock={true}
              anchorEl={anchorEl1}
              keepMounted
              open={Boolean(anchorEl1)}
              onClose={handleClose4}
            >
              {user?.kycStatusRes?.kycStatus !== "APPROVE" && user?.isAdmin && (
                <MenuItem
                  onClick={() => {
                    history.push("/become-creator");
                  }}
                >
                  Become a creator
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  history.push("/profile");
                }}
              >
                Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push("/create");
                }}
              >
                Create
              </MenuItem>
              {/* {!user?.userData?.name && (
                <MenuItem onClick={() => setUpdateName(true)}>
                  Edit Name
                </MenuItem>
              )} */}
              <MenuItem
                onClick={() => {
                  user.logoutHandler();
                  setAnchorEl1();
                }}
              >
                Disconnect
              </MenuItem>
            </StyledMenu>

            <Box>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  {SUPPORTED_WALLETS.map((item, i) => {
                    return (
                      <WalletCard
                        key={i}
                        className={metamaskhead}
                        setOpen2={setOpen2}
                        open2={open2}
                        isLoaderwallet={isLoaderwallet}
                        handleClickOpen2={handleClickOpen2}
                        onWalletConnectHandler={(data) => {
                          onWalletConnectHandler(data);
                          setSelectedWallet(data.name);
                        }}
                        index={i}
                        data={SUPPORTED_WALLETS[0]}
                      ></WalletCard>
                    );
                  })}
                </DialogContent>
              </Dialog>
            </Box>
          </Box>
          <Box>
            <StyledMenu
              id="simple-menu"
              disableScrollLock={true}
              anchorEl={anchorEl2}
              keepMounted
              open={Boolean(anchorEl2)}
              onClose={handleClose5}
            >
              <MenuItem
                onClick={() => {
                  user.setSelectNetwork(ChainIDBinance);
                  handleClose5();
                }}
              >
                <Box className={flexbox}>
                  <Box>
                    <img src="/images/binance.png" alt="" width="25px" />
                  </Box>
                  <Box
                    style={{
                      marginTop: "-7px",
                      paddingLeft: "10px",
                    }}
                  >
                    BSC Smart Chain
                  </Box>
                </Box>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  user.setSelectNetwork(ChainIDEthereum);
                  handleClose5();
                }}
              >
                <Box className={flexbox}>
                  <Box>
                    <img src="/images/ethereum.png" alt="" width="30px" />
                  </Box>
                  <Box
                    style={{
                      marginTop: "-7px",
                      paddingLeft: "10px",
                    }}
                  >
                    Ethereum
                  </Box>
                </Box>
              </MenuItem>
            </StyledMenu>
          </Box>
        </div>
      </Toolbar>
      // {/* </Container> */}
    );
  };

  const [searchInput, setSearchInput] = useState("");
  const searchTextRef = React.useRef(null);

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo.png" alt="" />

            {getDrawerChoices()}

            <div>
              {user?.isLogin ? (
                <IconButton
                  aria-label="delete"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick1}
                  className={menuMobile1}
                  size="small"
                >
                  <figure
                    style={
                      user?.isLogin
                        ? { height: "40px", width: "40px" }
                        : { width: "0px" }
                    }
                  >
                    <img
                      src={
                        user?.userData?.profilePic
                          ? user?.userData?.profilePic
                          : "/images/Profile.png"
                      }
                      alt=""
                    />
                  </figure>
                  <Typography
                    variant="h4"
                    title={
                      user?.userData?.name
                        ? user?.userData?.name
                        : user?.userData?.walletAddress
                    }
                  >
                    {" "}
                    {user?.userData?.name
                      ? user?.userData?.name.slice(0, 5) + ".."
                      : sortAddress(user?.userData?.walletAddress)}
                  </Typography>
                  <BsFillCaretDownFill
                    style={{ color: "#000", fontSize: "16px" }}
                  />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="delete"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={() => setUpdateMinSatkeOpen(true)}
                  className={menuMobile1}
                  size="small"
                >
                  <figure
                    style={
                      user?.isLogin
                        ? { height: "40px", width: "40px" }
                        : { width: "0px" }
                    }
                  >
                    <img
                      src={
                        user?.userData?.profilePic
                          ? user?.userData?.profilePic
                          : "/images/Profile.png"
                      }
                      alt=""
                    />
                  </figure>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    // to="/conect-wallet"
                    // component={Link}
                    onClick={handleClickOpen}
                    style={{ whiteSpace: "pre" }}
                  >
                    Connect Wallet
                  </Button>
                </IconButton>
              )}

              <Box className={divstake}>
                <Menu
                  id="simple-menu"
                  disableScrollLock={true}
                  anchorEl={anchorEl1}
                  keepMounted
                  open={Boolean(anchorEl1)}
                  onClose={handleClose4}
                >
                  {user?.kycStatusRes?.kycStatus !== "APPROVE" &&
                    user?.isAdmin && (
                      <MenuItem
                        onClick={() => {
                          history.push("/become-creator");
                        }}
                      >
                        Become a creator
                      </MenuItem>
                    )}
                  <MenuItem
                    onClick={() => {
                      history.push("/profile");
                    }}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push("/create");
                    }}
                  >
                    Create
                  </MenuItem>
                  {/* {!user?.userData?.name && (
                    <MenuItem onClick={() => setUpdateName(true)}>
                      Edit Name
                    </MenuItem>
                  )} */}
                  <MenuItem
                    onClick={() => {
                      user.logoutHandler();
                      setAnchorEl1();
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            </div>
          </div>
        </Drawer>
        {/* Qi wallet */}

        <Grid container alignItems="center">
          <Grid item xs={5}>
            <Box>{femmecubatorLogo}</Box>
          </Grid>
          <Grid item xs={5}>
            {SearchBoxx}
          </Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#197ab3", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton1,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box mt={1}>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const SearchBoxx = (
    <SearchBox
      search={search}
      inputRoot={inputRoot}
      inputInput={inputInput}
      searchIcon={searchIcon}
    />
  );

  const getMenuButtons = (activeClassName) => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          {/* <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton,
            }}
          >
            {label}
          </Button> */}
          <NavLink
            exact
            // to={`${href}`}
            {...{
              key: label,
              color: "inherit",
              to: href,
              // component: Link,
              className: menuButton,
              activeClassName: "active",
            }}
          >
            {" "}
            {label}
          </NavLink>
        </>
      );
    });
  };

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ backgroundColor: "#ccc0", border: "none" }}
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : "lg"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
    </>
  );
}
